import request from '~/services/request';
import { ChatMessageType } from '../types/messages';

export const getChatMessages = async (
	req: { webinarId: string },
	options: { [key: string]: any } = {},
): Promise<ChatMessageType[]> => {
	const { origin, ...rest } = options;
	const { webinarId } = req;

	return request({
		...rest,
		method: 'GET',
		uri: `${origin}/webinars/${webinarId}/chat`,
		withCredentials: true,
		maxAttempts: 2,
	});
};
