import React, { useEffect } from 'react';
import analytics from '~/services/analytics';

import { ON_AIR_VIEWER_PAGE, ON_AIR_STATUS } from './constants';
import Watch from './scenes/Watch';
import PreScreen from './scenes/PreScreen';
import EmbedPreScreen from './scenes/EmbedPreScreen';
import EmbedWatch from './scenes/EmbedWatch';

import { OnAirWebinarType, OnAirPageType } from './types';
import { Layout } from './styles';

const ON_AIR_DISPLAY = {
	[ON_AIR_VIEWER_PAGE.EMBED_PRESCREEN]: EmbedPreScreen,
	[ON_AIR_VIEWER_PAGE.EMBED_WATCH]: EmbedWatch,
	[ON_AIR_VIEWER_PAGE.PRESCREEN]: PreScreen,
	[ON_AIR_VIEWER_PAGE.WATCH]: Watch,
};

const DISPLAY_EVENT = {
	[ON_AIR_VIEWER_PAGE.EMBED_PRESCREEN]:
		analytics.events.ON_AIR_WEBINAR_EMBED_PRESCREEN_PAGE_VISITED,
	[ON_AIR_VIEWER_PAGE.PRESCREEN]:
		analytics.events.ON_AIR_WEBINAR_PRE_SCREEN_PAGE_VISITED,
	[ON_AIR_VIEWER_PAGE.EMBED_WATCH]:
		analytics.events.ON_AIR_WEBINAR_EMBED_WATCH_PAGE_VISITED,
	[ON_AIR_VIEWER_PAGE.WATCH]:
		analytics.events.ON_AIR_WEBINAR_WATCH_PAGE_VISITED,
};

const getDisplay = (page: OnAirPageType) => {
	return ON_AIR_DISPLAY[page];
};

const OnAirPageDisplay = ({
	page,
	webinar,
}: {
	page: OnAirPageType;
	webinar: OnAirWebinarType;
}) => {
	const DisplayComponent = getDisplay(page);

	const { isRegistrationEnabled, isUserRegistered, status } = webinar;

	useEffect(() => {
		if (!DISPLAY_EVENT[page]) {
			return;
		}
		analytics.track(DISPLAY_EVENT[page], {
			isRegistrationEnabled,
			isUserRegistered,
			status,
		});
	}, [isRegistrationEnabled, isUserRegistered, page, status]);

	useEffect(() => {
		if (!isRegistrationEnabled && status === ON_AIR_STATUS.CREATED) {
			analytics.track(
				analytics.events.ON_AIR_WEBINAR_REGISTRATION_OFF_PAGE_VISITED,
			);
		}
	}, [isRegistrationEnabled, status]);

	if (page === ON_AIR_VIEWER_PAGE.WATCH) {
		return <DisplayComponent />;
	}

	return (
		<Layout>
			<DisplayComponent />
		</Layout>
	);
};

export default OnAirPageDisplay;
