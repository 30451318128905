import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import OnAir from './OnAir';
import { JWTOnAir } from './types';
import OnAirSessionProvider from './contexts/Session';
import MessageManagerProvider from './contexts/MessageManager';

type OnAirPageProps = {
	isEmbedded: boolean;
	jwt: JWTOnAir;
	sessionRegistrationId: string;
	webinarId: string;
	sessionCountryCode?: string;
};

const queryClient = new QueryClient();

const OnAirPage = ({
	isEmbedded,
	jwt,
	sessionRegistrationId,
	webinarId,
	sessionCountryCode,
}: OnAirPageProps) => (
	<QueryClientProvider client={queryClient}>
		<OnAirSessionProvider
			isEmbedded={isEmbedded}
			jwt={jwt}
			sessionRegistrationId={sessionRegistrationId}
			sessionCountryCode={sessionCountryCode}
		>
			<MessageManagerProvider>
				<OnAir isEmbedded={isEmbedded} webinarId={webinarId} />
			</MessageManagerProvider>
		</OnAirSessionProvider>
	</QueryClientProvider>
);

export default OnAirPage;
