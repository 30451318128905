import { useCallback, useEffect, useRef } from 'react';
import logger, { castUnknownToError } from '~/services/logger';

import { ON_AIR_SUBSCRIBE } from '../constants';
import { WebsocketHeartbeatData } from '../types';

// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
const CLOSE_NORMAL_STATUS_CODE = 1000;

const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

type WebSocketBaseProps = {
	url: string;
	enabled: boolean;
	heartbeatData?: WebsocketHeartbeatData;
	isChatEnabled?: boolean;
	isViewerCountEnabled?: boolean;
	sessionRegistrationId?: string;
};

type WebSocketCallbackOptions = {
	onOpen?: (socket: WebSocket) => void;
	onMessage?: (msg: object) => void;
	onError?: (err: Event) => void;
	onClose?: (code: number) => void;
};

type Channel =
	| 'CHAT_CHANNEL'
	| 'VIEWERS_COUNT_CHANNEL'
	| 'WEBINAR_INVITATION_CHANNEL'
	| 'WEBINAR_STATUS_CHANNEL';
const useWebsocket = ({
	url,
	enabled,
	heartbeatData,
	isChatEnabled,
	isViewerCountEnabled,
	sessionRegistrationId,
	...cb
}: WebSocketBaseProps & WebSocketCallbackOptions) => {
	const socketRef = useRef<WebSocket | null>(null);
	const optionsCache = useRef<WebSocketCallbackOptions>(cb);
	optionsCache.current = cb;
	const activeSubscribers = useRef<Record<Channel, boolean>>({
		CHAT_CHANNEL: false,
		VIEWERS_COUNT_CHANNEL: false,
		WEBINAR_INVITATION_CHANNEL: false,
		WEBINAR_STATUS_CHANNEL: false,
	});

	const subscribeToEvent = useCallback(
		(channel: Channel) => {
			if (socketRef.current && !activeSubscribers.current[channel]) {
				socketRef.current.send(ON_AIR_SUBSCRIBE[channel]);
				activeSubscribers.current[channel] = true;
			}
		},
		[activeSubscribers],
	);

	const unsubscribeFromEvent = useCallback(
		(channel: Channel) => {
			if (socketRef.current && activeSubscribers.current[channel]) {
				socketRef.current.send(ON_AIR_SUBSCRIBE[`${channel}_UNSUBSCRIBE`]);
				activeSubscribers.current[channel] = false;
			}
		},
		[activeSubscribers],
	);

	const deactivateAllSubscribers = useCallback(() => {
		Object.keys(activeSubscribers.current).forEach(channel => {
			if (activeSubscribers.current[channel]) {
				activeSubscribers.current[channel] = false;
			}
		});
	}, [activeSubscribers]);

	useEffect(() => {
		const action = isViewerCountEnabled
			? subscribeToEvent
			: unsubscribeFromEvent;
		action('VIEWERS_COUNT_CHANNEL');
	}, [isViewerCountEnabled, subscribeToEvent, unsubscribeFromEvent]);

	useEffect(() => {
		const action = isChatEnabled ? subscribeToEvent : unsubscribeFromEvent;
		action('CHAT_CHANNEL');
	}, [isChatEnabled, subscribeToEvent, unsubscribeFromEvent]);

	useEffect(() => {
		const retryWithBackoffStrategy = (
			fn: () => void,
			{ retryCount = 1, retryReason },
		) => {
			const retryTimeout = Math.min(
				ON_AIR_SUBSCRIBE.MAXIMUM_BACKOFF_MILLISECONDS,
				2 ** retryCount * 1000,
			);
			logger.info('watchPage::Connection closed, retrying...', {
				retryTimeout,
				...retryReason,
			});
			socketRef.current = null;
			// fn();
			setTimeout(fn, retryTimeout);
		};

		const connect = (retryIteration: number = 0) => {
			let retryCount = retryIteration || 0;
			let heartbeatIntervalId: NodeJS.Timer;

			const socket = new WebSocket(url);
			socketRef.current = socket;

			socket.onopen = () => {
				optionsCache.current.onOpen?.(socket);

				if (isViewerCountEnabled) {
					subscribeToEvent('VIEWERS_COUNT_CHANNEL');
				}

				if (isChatEnabled) {
					subscribeToEvent('CHAT_CHANNEL');
				}

				subscribeToEvent('WEBINAR_STATUS_CHANNEL');
				subscribeToEvent('WEBINAR_INVITATION_CHANNEL');

				if (heartbeatIntervalId) {
					clearInterval(heartbeatIntervalId);
				}

				heartbeatIntervalId = setInterval(() => {
					// Send heartbeat every 15 to 25 seconds
					socket.send(ON_AIR_SUBSCRIBE.HEARTBEAT_COMMAND(heartbeatData));
				}, randomIntFromInterval(15000, 25000));
				retryCount = 0;
			};

			socket.onmessage = event => {
				if (event && event.data) {
					optionsCache.current.onMessage?.(JSON.parse(event.data));
				}
			};

			socket.onclose = ({ code, reason, wasClean }) => {
				optionsCache.current.onClose?.(code);

				deactivateAllSubscribers();

				if (enabled && code !== CLOSE_NORMAL_STATUS_CODE) {
					if (heartbeatIntervalId) {
						clearInterval(heartbeatIntervalId);
					}
					retryWithBackoffStrategy(() => connect(retryCount + 1), {
						retryCount,
						retryReason: {
							code,
							reason,
							wasClean,
						},
					});
				} else {
					clearInterval(heartbeatIntervalId);
				}
			};

			socket.onerror = err => {
				deactivateAllSubscribers();

				if (heartbeatIntervalId) {
					clearInterval(heartbeatIntervalId);
				}

				if (enabled) {
					optionsCache.current.onError?.(err);

					// Log error only every 6th attempt to reduce noise
					if (retryCount % 6 === 0) {
						const error = castUnknownToError(err);
						logger.captureException({
							error,
							message:
								'watchPage::Connection error. Cannot fetch webinar status',
							tags: { onair: true },
						});
					}
				}
			};
		};

		if (enabled) {
			connect();
		}

		return () => {
			socketRef.current?.close(CLOSE_NORMAL_STATUS_CODE);
		};
	}, [enabled, url]); // eslint-disable-line

	const getSocket = useCallback(() => socketRef.current, []);

	return {
		getSocket,
	};
};

export default useWebsocket;
