/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import OnAir from '../../scenes/OnAir';
import WrapWithProviders from '../ssrWrapper';

const removeQueryParam = (router, param) => {
	const { pathname, query } = router;
	const params = new URLSearchParams(query);
	params.delete(param);
	router.replace({ pathname, query: params.toString() }, undefined, {
		shallow: true,
	});
};

const OnAirPage = ({ i18n, router, ...props }) => {
	useEffect(() => {
		if (router.isReady && router.query.token) {
			removeQueryParam(router, 'token');
		}
	}, [router]);

	return (
		<WrapWithProviders i18n={i18n} {...props}>
			<OnAir {...props} />
		</WrapWithProviders>
	);
};

export default OnAirPage;
