import React, { useCallback, useEffect, useState } from 'react';

import Spinner from '~/components/Spinner';
import logger from '~/services/logger';
import { ReactionType } from '@sy/onair-shared';
import spiderSense, { Severity } from '~/services/spidersense';
import OnAirProvider from './contexts/OnAir';
import OnAirPageDisplay from './OnAirPageDisplay';

import useWebinar from './hooks/useWebinar';
import {
	OnAirStudioInvitation,
	OnAirStudioInvitationMessageType,
	OnAirWebinarType,
} from './types';
import { getPage, getShouldUseWebsocket, getTimePaddings } from './utils';
import { useSession } from './contexts/Session';
import { useMessageManager } from './contexts/MessageManager';
import { MessageEventType, ReactionEventType } from './types/messages';
import { getChatMessages } from './api/getChatMessages';

const OnAir = ({
	isEmbedded,
	webinarId,
}: {
	isEmbedded: boolean;
	webinarId: string;
}) => {
	const { requestOptions, userId } = useSession();
	const { messageManager } = useMessageManager();

	const [webinar, setWebinar] = useState<OnAirWebinarType>();
	const [invitation, setInvitation] = useState<OnAirStudioInvitation>();

	// compute on update so time paddings are consistent throughout the app
	const { isStartingSoon, hasFinishedRecently } = getTimePaddings(webinar);

	const page = getPage({
		isStartingSoon,
		isEmbedded,
		webinar,
	});

	const shouldUseWebsocket = getShouldUseWebsocket({
		hasFinishedRecently,
		isStartingSoon,
		page,
		webinar,
	});

	const onMessage = useCallback(
		(
			data?:
				| Partial<OnAirWebinarType>
				| OnAirStudioInvitationMessageType
				| MessageEventType
				| ReactionEventType,
		) => {
			if (!data) return;

			if ('type' in data && data.type === 'invitation') {
				const { type, ...rest } = data;
				setInvitation(rest);

				return;
			}

			if ('message' in data) {
				const { message } = data;
				const { action, ...messageData } = message;

				switch (action) {
					case 'add':
						messageManager.addMessage(
							messageData.text,
							messageData.timestamp,
							messageData.user,
						);
						break;
					case 'delete':
						messageManager.deleteMessage(
							messageData.timestamp,
							messageData.user.id,
						);
						break;
					case 'pin':
						messageManager.pinMessage(
							messageData.timestamp,
							messageData.user.id,
						);
						break;
					case 'unpin':
						messageManager.unpinMessage(
							messageData.timestamp,
							messageData.user.id,
						);
						break;
					default:
						logger.warn('Unknown message action', { action });
						break;
				}
			}

			if ('reaction' in data) {
				const { reaction } = data;
				const { action, ...reactionData } = reaction;

				switch (action) {
					case 'add':
						messageManager.addReaction(
							reactionData.messageRef,
							reactionData.reaction as ReactionType,
							reactionData.userId,
							userId,
						);
						break;
					case 'remove':
						messageManager.removeReaction(
							reactionData.messageRef,
							reactionData.reaction as ReactionType,
							reactionData.userId,
							userId,
						);
						break;
					default:
						logger.warn('Unknown reaction action', { action });
						break;
				}
			}

			setWebinar((existing?: OnAirWebinarType) => ({
				...((existing || {}) as OnAirWebinarType),
				...data,
			}));
		},
		[messageManager, userId],
	);

	const { refetch: refetchWebinar, getSocket: getSubscribeSocket } = useWebinar(
		{
			onMessage,
			isChatEnabled: webinar?.isChatEnabled ?? true,
			isViewerCountEnabled: webinar?.isViewerCountEnabled ?? true,
			shouldUseWebsocket,
			webinarId,
		},
	);

	const socket = getSubscribeSocket();

	useEffect(() => {
		// This effect runs whenever the webinar or socket state changes.
		if (
			socket?.readyState === 1 &&
			webinar?.id &&
			webinar?.isChatEnabled &&
			webinar?.useNewChat
		) {
			// Fetch chat messages if the webinar exists, chat is enabled, and useNewChat is true.
			// A readyState of 1 means the socket is connected. If we lose and regain connection to the socket, we fetch messages again
			// to ensure chat history is not lost.
			const fetchChatMessages = async () => {
				try {
					const pastMessages = await getChatMessages(
						{ webinarId: webinar.id },
						requestOptions,
					);
					messageManager.bulkAddMessages(pastMessages);
				} catch (error) {
					spiderSense.track({
						severity: Severity.ERROR,
						categories: ['onair', 'fetchChatMessages'],
						info: { webinarId: webinar.id },
					});
				}
			};

			fetchChatMessages();
		}
	}, [
		messageManager,
		requestOptions,
		socket?.readyState,
		webinar?.id,
		webinar?.isChatEnabled,
		webinar?.useNewChat,
	]);

	if (!webinar) {
		return (
			<div>
				<Spinner id={`onair-loading-${webinarId}`} />
			</div>
		);
	}

	return (
		<OnAirProvider
			getSubscribeSocket={getSubscribeSocket}
			invitation={invitation}
			isEmbedded={isEmbedded}
			refetchWebinar={refetchWebinar}
			webinar={webinar}
		>
			<OnAirPageDisplay page={page} webinar={webinar} />
		</OnAirProvider>
	);
};

export default OnAir;
