import { useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { useMountedState } from '~/services/utils/hooks';
import { getWebinar } from '../../api';
import {
	OnAirStudioInvitationMessageType,
	OnAirWebinarType,
	WebsocketHeartbeatData,
} from '../../types';
import { useSession } from '../../contexts/Session';

import useWebsocket from '../useWebsocket';
import { useOnAirPaths } from '../useOnAirPaths';
import { MessageEventType, ReactionEventType } from '../../types/messages';

const getRefreshIntervalTime = ({
	isUserRegistered,
	shouldUseWebsocket,
	isWSConnected,
}: {
	isUserRegistered: boolean | undefined;
	shouldUseWebsocket: boolean;
	isWSConnected: boolean;
}) => {
	if (isUserRegistered) {
		if (shouldUseWebsocket) {
			return isWSConnected ? 60000 : 15000;
		}
		return 60000;
	}

	return 0;
};

const useWebinar = ({
	onMessage,
	isChatEnabled,
	isViewerCountEnabled,
	shouldUseWebsocket,
	webinarId,
}: {
	onMessage: (
		data?:
			| Partial<OnAirWebinarType>
			| OnAirStudioInvitationMessageType
			| MessageEventType
			| ReactionEventType,
	) => void;
	isChatEnabled: boolean;
	isViewerCountEnabled: boolean;
	shouldUseWebsocket: boolean;
	webinarId: string;
}) => {
	const { query, requestOptions, sessionRegistrationId } = useSession();
	const { subscribeWs } = useOnAirPaths();
	const [isWSConnected, setIsWsConnected] = useState(false);
	const isMounted = useMountedState();
	const websocketHeartbeatData = useRef<WebsocketHeartbeatData>({
		streamId: undefined,
	});

	const { getSocket } = useWebsocket({
		enabled: shouldUseWebsocket,
		url: `${subscribeWs}/${webinarId}/status${query}`,
		heartbeatData: websocketHeartbeatData.current,
		isChatEnabled,
		isViewerCountEnabled,
		sessionRegistrationId,
		onMessage: (
			data:
				| Partial<OnAirWebinarType>
				| OnAirStudioInvitationMessageType
				| MessageEventType
				| ReactionEventType,
		) => {
			onMessage(data);
		},
		onOpen: () => {
			if (isMounted()) {
				setIsWsConnected(true);
			}
		},
		onClose: () => {
			if (isMounted()) {
				setIsWsConnected(false);
			}
		},
	});

	const { refetch } = useQuery({
		queryKey: ['webinar', webinarId],
		queryFn: async () => getWebinar({ id: webinarId }, requestOptions),
		refetchIntervalInBackground: true,
		refetchInterval: data =>
			getRefreshIntervalTime({
				isWSConnected,
				shouldUseWebsocket,
				isUserRegistered: data?.isUserRegistered,
			}),
		onSuccess: (data: OnAirWebinarType) => {
			onMessage(data);
			// Update the our websocket heartbeat data with the current stream ID
			websocketHeartbeatData.current.streamId = data.streamId;
		},
	});

	return { getSocket, refetch };
};

export default useWebinar;
